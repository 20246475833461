<template>
    <b-modal 
        id="ModalTermsAndConditions" 
        modal-class="dialog-600"
        no-close-on-esc 
        no-close-on-backdrop
        centered>
        <template #modal-header="{ close }">
            <h5 class="title-terms-and-conditions"><strong>Términos y Condiciones - Modelo Freemium</strong></h5>
            <feather-icon
                class="cursor-pointer"
                @click="close()"
                icon="XIcon"
                size="20"
            />   
        </template>
        <div class="scrollable-container">
            <p>
                ¡Gracias por elegir nuestra nueva modalidad, el "Modelo Freemium", ofrecido por Enviame!
            </p>
            <p>A continuación, te informamos sobre los puntos clave de este modelo, diseñado para beneficiar a nuestros nuevos usuarios:</p>
            <ul class="mt-2 mb-2">
                <li><strong>Beneficios iniciales:</strong> Enviame proporcionará a todas las nuevas empresas que se incorporen mediante el Modelo Freemium los primeros tres meses de envíos gratuitos.</li>
                <li><strong>Vigencia de la oferta</strong> El período para disfrutar de envíos gratuitos bajo el modelo Freemium es desde el 13 de Agosto de 2024 hasta el 13 de Noviembre de 2024.</li>
                <li><strong>Posterior al período inicial:</strong> Una vez finalizados los tres meses iniciales, la cantidad de envíos por ciclos mensuales  gratuitos serán de 200 envíos por el período de un año, quedando la vigencia desde 13 de Noviembre de 2024 al 13 de Noviembre de 2025.</li>
                <li><strong>Requisitos para acceder al beneficio:</strong> Para poder aprovechar esta oferta, la empresa no debe contar con el módulo Marketcenter.</li>
                <li><strong>Registro en Mercado Libre:</strong> Es necesario estar registrado en Mercado Libre para acceder a este beneficio, debido a que va a ser necesario autenticar mediante Mercado Libre.</li>
                <li><strong>Opciones después del período gratuito:</strong> Al finalizar los envíos gratuitos, la empresa tiene la opción de convertirse en usuario pagos o adquirir más envíos bajo el modelo Freemium.</li>
            </ul>
            <p>Además informamos que la confidencialidad sobre la información de nuestros clientes es fundamental para Enviame, por lo que a continuación transparentamos los términos de uso sobre la información obtenida desde la aplicación.</p>
            <ul class="mt-2 mb-2">
                <li>Enviame solo almacena el correo electrónico del merchant, el cual es necesario para poder interactuar con la plataforma.</li>
                <li>Enviame no comercializa ni distribuye la información de los merchant ni de sus clientes.</li>
                <li>Enviame mantiene toda su operación en entornos Cloud con políticas de confidencialidad y continuidad de la información.</li>
                <li>Enviame no realiza análisis estadísticos ni comerciales con las cookies.</li>
                <li>Enviame cuenta con políticas de seguridad de la información.</li>
                <li>En el enlace se encuentra nuestra Política de privacidad.</li>
                <li>La seguridad de nuestra aplicación es puesta a prueba anualmente en un ethical hacking por una empresa externa a Enviame.</li>
            </ul>
            <p>Puede presionar “Continuar” si está de acuerdo con los términos. En caso de tener dudas nos puede contactar en support@enviame.io</p>
        </div>
        <template #modal-footer>
            <div class="d-flex justify-content-end">
                <b-button 
                size="md" 
                class="mr-1"
                variant="outline-secondary" 
                @click="cancel()"
                >
                    Declinar
                </b-button>
                <div class="d-flex">
                    <b-button 
                        size="md" 
                        variant="success" 
                        @click="ok()"
                    >
                        Continuar
                    </b-button>
                </div>
            </div>
        </template>
    </b-modal>
</template>
<script>
import {environment} from '@/environments/environment'
export default {
  name: 'modal-terms-and-conditions',
  methods: {
    close () {
      this.$bvModal.hide('ModalTermsAndConditions')
    },
    cancel () {
      this.close()
    },
    ok () {
      window.parent.location.href = `${environment.ottawaMeliRedirect}/webhook/mercado-libre/authenticate?ecommerce=mercado-libre&marketcenter_code=ML_CL&freemium=true`
    }
  }
}
</script>
<style>
#ModalTermsAndConditions .modal-header {
    margin-top: 0.5rem;
    background-color: #ffffff;
    border-bottom: 1px solid #ebe9f1;
    
}
#ModalTermsAndConditions .modal-footer {
    border-top: 1px solid #ebe9f1;
}
#ModalTermsAndConditions .modal-body {
    padding-right: 0;
}
.title-terms-and-conditions {
    text-align: start !important;
    font-size: 16px !important;
}
.link-privacity {
    text-decoration: underline;
}
.scrollable-container {
  overflow-y: auto;
  max-height: 500px;
  height: 500px;
  overflow-x: hidden;
  margin-right: 10px;
}
.scrollable-container::-webkit-scrollbar {
  width: 6px;
}
.scrollable-container::-webkit-scrollbar-thumb{
  background-color: #686B6C;
  border-radius: 5px;
}
</style>