<template>
    <div class="login-meli">
      <div class="login-meli__circle login-meli__circle--white"></div>
      <div class="login-meli__internal animated fadeInDown">
          <div class="login-meli__login">
            <div class="login-meli__login-info login-meli__login-margin--mobile login-meli__login--width">
                <div class="d-flex align-items-center login-meli__login--img-container">
                    <img src="@/assets/images/login/Logos-grandes.png"  alt="">
                </div>                 
                <div class="w-100">
                    <div class="login-meli__animation-icon--text-desktop-mobile">
                        <h2>¡Prueba MarketCenter gratis por 3 meses!</h2>
                    </div>
                    <h2 class="login-meli__login--info-login text-center">
                        Conéctate con nosotros a través de 
                        <strong>Mercado Libre</strong>
                            y 
                        <strong>aumenta tu eficiencia</strong>
                        operacional con nuestro servicio de 
                        <strong>MarketCenter</strong>
                    </h2>
                </div>
                <div class="d-flex flex-column align-items-center login-meli__container--btn">
                <b-button class="mt-1 login-meli__login--btn-login" @click="openModal" block>
                    <span>{{$t('Quiero conectarme')}}</span>
                </b-button>
                <div class="login-meli__animation-icon--promotion text-center">
                    <p>Promoción exclusiva para sellers de MercadoLibre.</p>
                    <p>Válida desde el 13/08/2024 hasta el 13/11/2024.</p>
                </div>
                </div>
            </div>
          </div> 
          <div class="login-meli__dividing-line"></div>
          <div class="login-meli__animation-icon">
            <div class="login-meli__animation-icon-info">
                <div class="login-meli__animation-icon--text-desktop">
                  <h2>¡Prueba MarketCenter</h2>
                  <h2>gratis por 3 meses!</h2>
              </div>
              <div class="login-meli__animation-icon--benefit">
                <div>
                    <li class="login-meli__animation-icon--delay login-meli__animation-icon--delay-1"><img src="@/assets/images/login/check-green.png" width= "40"  alt=""><span>Rescata las etiquetas de forma masiva.</span></li>             
                    <li class="login-meli__animation-icon--delay login-meli__animation-icon--delay-2"><img src="@/assets/images/login/check-green.png" width= "40"  alt=""><span>Gestiona los manifiestos de tus envíos.</span></li>
                </div>
                <div>
                    <li class="login-meli__animation-icon--delay login-meli__animation-icon--delay-3"><img src="@/assets/images/login/check-green.png" width= "40"  alt=""><span>Imprime grupos masivos de etiquetas.</span></li>                         
                    <li class="login-meli__animation-icon--delay login-meli__animation-icon--delay-4"><img src="@/assets/images/login/check-green.png" width= "40"  alt=""><span>Centraliza toda tu operación de marketplaces.</span></li>                         
                </div>    
              </div>
            </div>
              
          </div>
      </div>   
      <modal-terms-and-conditions ref="ModalTermsAndConditions"/>
    </div>
</template>
  
<script>
import ModalTermsAndConditions from './components/ModalTermsAndConditions.vue'
import { BButton } from 'bootstrap-vue'

export default {
  components: { ModalTermsAndConditions, BButton },
  methods: {
    openModal () {
      this.$bvModal.show('ModalTermsAndConditions')
    }
  }
}
</script>
<style lang="scss">
.login-meli {
    position: relative;
    z-index: 1;
    overflow: hidden;
    background: linear-gradient(90deg, #31aee4 0%, white 35%, #fed100 100%);
    min-height: 100vh;
}
.login-meli__circle {
    position: absolute;
    overflow: hidden;
    z-index: 2;
    width: 96%;
    height: 200%;
    transform: translate(2%, -25%);
}
.login-meli__circle--white {
    background-color: #FFFF;
    border-radius: 50%;
}
.login-meli__login--img-container-line {
    border: none; 
    border-left: 1px solid #091E3F; 
    height: 75px;
    margin: 1rem 1.5rem;
}
.login-meli__login--img-container {
    margin-bottom: 4rem;
    max-width: 100%;
}
.login-meli__login--img-container img {
    max-width: 100%;
    height: auto;
}
.login-meli__login-info {
    display: grid;
    grid-template-rows: 33% 33% 34%;
    place-items: center;
}
.login-meli__login--width {
    width: 75%;
}
.login-meli__internal {
    position: relative;
    z-index: 100 !important;
    display: grid;
    grid-template-columns: 1fr 1px 1fr;
    min-height: 100vh;
}
.login-meli__login {
    padding: 4rem 4rem 4rem 8rem;
}
.login-meli__login, .login-meli__animation-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.login-meli__login--info-login {
    font-size: 1.5rem;
    margin: 2rem 0 5rem;
}
.login-meli__login--info-login, 
.login-meli__animation-icon, 
.login-meli__animation-icon div h2, 
.login-meli__animation-icon--promotion p  {
    color: rgba(24, 25, 94, 1);
}
.login-meli__animation-icon {
    align-items: inherit;
    padding: 4rem 8rem;
}
.login-meli__animation-icon-info {
    display: grid;
    grid-template-rows: 33% 67%;
}
.login-meli__animation-icon div h2 {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 600;
}
.login-meli__animation-icon--text-desktop-mobile {
    display: none;
}
.login-meli__animation-icon--text-desktop {
    margin-bottom: 4rem;
}
.login-meli__animation-icon--promotion p {
    font-size: 1.125rem;
    margin: 00.5rem 0;
}
.login-meli__animation-icon--delay {
    list-style:none; 
    margin-bottom: 3.5rem;
    font-size: 1.25rem;
    font-weight: 500;
    -moz-animation: show-icon 4.5s;
    -o-animation: show-icon 4.5s;
    -webkit-animation: show-icon 4.5s;
    animation: show-icon 4.5s;
}
.login-meli__animation-icon--delay-4 {
    animation-delay: -1s;
}

.login-meli__animation-icon--delay-3 {
    animation-delay: -1.5s;
}

.login-meli__animation-icon--delay-2 {
    animation-delay: -2s;
}

.login-meli__animation-icon--delay-1 {
    animation-delay: -2.5s;
}
/* Animación para mostrar/ocultar los iconos y textos*/
@-moz-keyframes show-icon {        
    from {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes show-icon {        
    from {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@keyframes show-icon {        
    from {
        opacity: 1;
    }
    25% {
        opacity: 0;
    }
    50% {
        opacity: 0;
    }
    75% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
} 
.login-meli__animation-icon img {
    margin-right: 1.5rem;
}
.login-meli__container--btn {
    width: 90%;
}
.login-meli__login--btn-login {
    background: linear-gradient(90deg, #F49143 -21.52%, #F4BB43 104.43%);
    color: #FFFFFF;
    border: none;
    font-weight: 700;
    margin-bottom: 1rem;
    padding: 1rem 1.5rem;
    border-radius: 13px;
}
.login-meli__login--btn-login:hover {
    background: linear-gradient(90deg, #f5852c -21.52%, #f7b52c 104.43%);
    color: #FFFFFF;
}
.login-meli__login--btn-login span{    
    font-size: 1.5rem;
}
.login-meli__dividing-line {
    height: 600px;
    background-color: #D3CDCD;
    margin: auto 0;
}
@media (max-width: 870px) {
    .login-meli {
        position: relative;
        z-index: 1;
        overflow: hidden;
        background: linear-gradient(0deg, rgba(254,209,0,1) 1%, rgba(49,174,228,1) 100%);
        min-height: 100vh;
    }
    .login-meli__circle {
        border-radius: 48%;
        width: 200%; 
        height: 90%;
        transform: translate(-25%, 5.8%);
    }
    .login-meli__internal {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 1rem 2rem;
    }
    .login-meli__login--img-container-line {
        height: 40px;
        margin: 0.5rem 1rem;
    }
    .login-meli__login--width, .login-meli__container--btn {
        width: 100%
    }
    .login-meli__login--img-container {
        justify-content: center;
        margin: 0;
    }
    .login-meli__login--info-login {
        margin: 2rem 0;
        font-size: 1rem;
    }
    .login-meli__login, .login-meli__animation-icon {
        padding: 0;
    }
    .login-meli__login {
        justify-content: flex-start;
        height: 50%;
    }
    .login-meli__login--btn-login{
        border-radius: 8px;
        padding: 0.786rem 1.5rem;
    }
    .login-meli__login--btn-login span{    
        font-size: 1rem;
    }
    .login-meli__dividing-line {
        display: none;
    }
    .login-meli__animation-icon {
        justify-content: start;
    }
    .login-meli__animation-icon div h2 {
        font-size: 1rem;
    }
    .login-meli__animation-icon--promotion p {
        font-size: 0.75rem;
        margin: 0;
    }
    .login-meli__animation-icon--benefit {
        display: flex;
        margin: 2rem 0;
    }
    .login-meli__animation-icon--benefit div{
        display: flex;
        flex-direction: column;
        align-items: start;
        margin: 0 0.5rem;
    }
    .login-meli__animation-icon--delay {
        font-size: 0.75rem;
        animation: none;
        margin-bottom: 1.5rem;
        display: flex;
        align-items: center;
    }
    .login-meli__animation-icon img {
        margin-right: 0.5rem;
        width: 22px;
    } 
    .login-meli__animation-icon--text-desktop-mobile {
        display: block;
        text-align: center;
        margin-top: 2rem;
    }
    .login-meli__animation-icon--text-desktop-mobile h2 {
        font-size: 1.375rem;
        font-weight: bold;
        color: rgba(24, 25, 94, 1);
    }
    .login-meli__animation-icon--text-desktop {
        display: none;
    }
}
</style>